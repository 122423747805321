<template>
  <div class="pdf">
    <VueHtml2pdf
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      filename="Application Letter"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div
          ref="appl"
          width="595px"
          style="
            font-family: Arial, sans-serif !important;
            background-color: #fff;
            padding: 16px;
            color: #000;
          "
          class="letterOfApplication ech"
        >
          <div class="w-100">
            <div class="bod">
              <div
                style="display: flex; justify-content: flex-end; width: 100%"
              >
                <p
                  v-if="isIndividual"
                  style="
                    font-family: Arial, sans-serif !important;
                    max-width: 50%;
                    text-align: right;
                  "
                >
                  {{ uname }}
                </p>
              </div>
              <div
                style="display: flex; justify-content: flex-end; width: 100%"
              >
                <p
                  style="
                    font-family: Arial, sans-serif !important;
                    max-width: 50%;
                    text-align: right;
                  "
                  ref="addrss1"
                ></p>
              </div>
              <p
                style="
                  font-family: Arial, sans-serif !important;
                  max-width: 50%;
                  text-align: left;
                "
              >
                <strong>DATE: </strong>{{ today }}
              </p>
              <p
                style="
                  font-family: Arial, sans-serif !important;
                  max-width: 50%;
                  text-align: left;
                "
              >
                The Branch Manager, <br />
                Sterling Bank Plc. <br />
                20 Marina, <br />
                Lagos
                <br /><br />
                Dear Sir,
              </p>
              <p
                class="w-100 tl"
                style="
                  font-family: Arial, sans-serif !important;
                  width: 100%;
                  text-align: center;
                  text-decoration: underline;
                  font-weight: 700;
                "
              >
                LOAN APPLICATION LETTER
                {{ isBusiness ? "FOR BUSINESS NAME/CORPORATES" : "" }}
              </p>
              <p style="font-family: Arial, sans-serif !important" class="para">
                {{ isIndividual ? "I" : "We" }} {{ uname }} hereby apply for an
                Imperium Lease credit facility of {{ price }} to purchase a
                {{ product + " system" }} for a term of 2 years.
              </p>
              <p style="font-family: Arial, sans-serif !important" class="para">
                {{ isIndividual ? "I" : "We" }} consent to the debit of any
                amount that may be applicable as fees and monthly rental from my
                {{ ins.bank }} account number {{ ins.account_number }} or any
                other accounts standing by my credit and domiciled with the Bank
                or other financial institution in the event of default.
              </p>
              <p style="font-family: Arial, sans-serif !important" class="para">
                {{ isIndividual ? "I" : "We" }} consent to the collection and
                disclosure of my transaction information to the appointed credit
                bureaus and use of the information by the Bank for any approved
                business purposes as may from time to time be prescribed by the
                bank or any relevant statutes.
              </p>
              <p style="font-family: Arial, sans-serif !important" class="para">
                {{ isIndividual ? "I" : "We" }} consent to the conduct of credit
                checks on me and the retrieval of my credit history as may be
                required from other financial institutions through any relevant
                means or agency.
              </p>
              <p
                style="font-family: Arial, sans-serif !important"
                v-if="existing.banks.length > 1"
                class="para"
              >
                {{ isIndividual ? "I" : "We" }} hereby confirm that my total
                current indebtedness is the sum of {{ existing.amount }} with
                {{ existing.banks }}
              </p>
              <p style="font-family: Arial, sans-serif !important" class="para">
                {{ isIndividual ? "I" : "We" }} also consent to the debit of the
                sum of {{ downpayment }}, being down payment of the equipment
                purchase as our (equity contribution, management, and insurance
                fee) towards the acquisition of the Renewable Energy Solution.
              </p>
              <p style="font-family: Arial, sans-serif !important" class="para">
                Thank you.
              </p>
              <div
                style="
                  display: flex;
                  align-items: flex-end;
                  justify-content: space-between;
                "
              >
                <div>
                  <p
                    style="font-family: Arial, sans-serif !important"
                    class="para"
                  >
                    Yours faithfully,
                  </p>
                  <p
                    style="
                      font-family: Arial, sans-serif !important;
                      margin-bottom: 0;
                    "
                    class="bd"
                    v-if="isIndividual"
                  >
                    <strong> {{ uname }}</strong>
                  </p>
                  <img
                    v-if="isBusiness"
                    :src="sign"
                    style="max-width: 120px"
                    width="120px"
                    height="90px"
                    alt=""
                  />
                  <hr
                    style="border: none; border-top: 1px solid #000; margin: 0"
                  />
                  <p
                    style="
                      font-family: Arial, sans-serif !important;
                      margin-bottom: 0;
                    "
                    class="bd"
                    v-if="isBusiness"
                  >
                    <strong>{{ director1 }}</strong>
                  </p>
                  <p
                    v-if="isIndividual"
                    style="font-family: Arial, sans-serif !important"
                  >
                    <strong>Customer's Name</strong>
                  </p>
                  <p v-else style="font-family: Arial, sans-serif !important">
                    Director's Name
                  </p>
                </div>
                <div>
                  <img
                    v-if="isIndividual"
                    :src="sign"
                    style="max-width: 120px"
                    width="120px"
                    height="90px"
                    alt=""
                  />
                  <hr
                    style="border: none; border-top: 1px solid #000; margin: 0"
                  />
                  <p
                    style="
                      font-family: Arial, sans-serif !important;
                      margin-bottom: 0;
                    "
                    class="bd"
                    v-if="isBusiness"
                  >
                    <strong>{{ director2 }}</strong>
                  </p>
                  <p v-if="isIndividual" style="width: 100%; text-align: left">
                    <strong>Signature</strong>
                  </p>
                  <p v-if="isBusiness" style="width: 100%; text-align: left">
                    Director/Secretary's Name
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </VueHtml2pdf>

    <div
      ref="appl"
      width="595px"
      height="842px"
      style="
        height: 842px;
        font-family: Arial, sans-serif !important;
        background-color: #fff;
        padding: 16px;
        color: #000;
      "
      class="letterOfApplication ech"
    >
      <div
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
        class="w-100"
      ></div>
      <div class="w-100">
        <div class="bod">
          <div style="display: flex; justify-content: flex-end; width: 100%">
            <p
              style="
                font-family: Arial, sans-serif !important;
                max-width: 50%;
                text-align: right;
              "
              ref="addrss"
            ></p>
          </div>
          <p
            class="w-100 tl"
            style="
              font-family: Arial, sans-serif !important;
              width: 100%;
              text-align: center;
              text-decoration: underline;
              font-weight: 700;
            "
          >
            LOAN APPLICATION LETTER
            {{ isBusiness ? "FOR BUSINESS NAME/CORPORATES" : "" }}
          </p>
          <p style="font-family: Arial, sans-serif !important" class="para">
            {{ isIndividual ? "I" : "We" }} {{ uname }} hereby apply for an
            Imperium Lease credit facility of {{ price }} to purchase a
            {{ product + " system" }} for a term of 2 years.
          </p>
          <p style="font-family: Arial, sans-serif !important" class="para">
            {{ isIndividual ? "I" : "We" }} consent to the debit of any amount
            that may be applicable as fees and monthly rental from my
            {{ ins.bank }} account number {{ ins.account_number }} or any other
            accounts standing by my credit and domiciled with the Bank or other
            financial institution in the event of default.
          </p>
          <p style="font-family: Arial, sans-serif !important" class="para">
            {{ isIndividual ? "I" : "We" }} consent to the collection and
            disclosure of my transaction information to the appointed credit
            bureaus and use of the information by the Bank for any approved
            business purposes as may from time to time be prescribed by the bank
            or any relevant statutes.
          </p>
          <p style="font-family: Arial, sans-serif !important" class="para">
            {{ isIndividual ? "I" : "We" }} consent to the conduct of credit
            checks on me and the retrieval of my credit history as may be
            required from other financial institutions through any relevant
            means or agency.
          </p>
          <p
            style="font-family: Arial, sans-serif !important"
            v-if="existing.banks.length > 1"
            class="para"
          >
            {{ isIndividual ? "I" : "We" }} hereby confirm that my total current
            indebtedness is the sum of {{ existing.amount }} with
            {{ existing.banks }}
          </p>
          <p style="font-family: Arial, sans-serif !important" class="para">
            {{ isIndividual ? "I" : "We" }} also consent to the debit of the sum
            of {{ downpayment }}, being down payment of the equipment purchase
            as our (equity contribution, management, and insurance fee) towards
            the acquisition of the Renewable Energy Solution.
          </p>
          <p style="font-family: Arial, sans-serif !important" class="para">
            Thank you.
          </p>
          <div
            style="
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
            "
          >
            <div>
              <p style="font-family: Arial, sans-serif !important" class="para">
                Yours faithfully,
              </p>
              <p
                style="
                  font-family: Arial, sans-serif !important;
                  margin-bottom: 0;
                "
                class="bd"
                v-if="isIndividual"
              >
                <strong> {{ uname }}</strong>
              </p>
              <img
                v-if="isBusiness && show"
                :src="sign"
                style="max-width: 120px"
                width="120px"
                height="90px"
                alt=""
              />
              <hr style="border: none; border-top: 1px solid #000; margin: 0" />
              <p
                style="
                  font-family: Arial, sans-serif !important;
                  margin-bottom: 0;
                "
                class="bd"
                v-if="isBusiness"
              >
                <strong>{{ director1 }}</strong>
              </p>
              <p
                v-if="isIndividual"
                style="font-family: Arial, sans-serif !important"
              >
                <strong>Customer's Name</strong>
              </p>
              <p v-else style="font-family: Arial, sans-serif !important">
                Director's Name
              </p>
            </div>
            <div>
              <img
                v-if="isIndividual && show"
                :src="sign"
                style="max-width: 120px"
                width="120px"
                height="90px"
                alt=""
              />
              <hr style="border: none; border-top: 1px solid #000; margin: 0" />
              <p
                style="
                  font-family: Arial, sans-serif !important;
                  margin-bottom: 0;
                "
                class="bd"
                v-if="isBusiness"
              >
                <strong>{{ director2 }}</strong>
              </p>
              <p v-if="isIndividual" style="width: 100%; text-align: left">
                <strong>Signature</strong>
              </p>
              <p v-if="isBusiness" style="width: 100%; text-align: left">
                Director/Secretary's Name
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
export default {
  props: [
    "uname",
    "sign",
    "show",
    "naira",
    "product",
    "ins",
    "existing",
    "price",
    "downpayment",
    "download",
    "isBusiness",
    "isIndividual",
    "director1",
    "director2",
  ],
  components: {
    VueHtml2pdf,
  },
  methods: {
    formatAddress(address) {
      // console.log(address.replace(/,/g, ",<br>"));
      return address.replace(/,/g, ",<br>");
    },
  },
  computed: {
    today() {
      var date = new Date();
      var day = (date.getDate() < 10 ? "0" : "") + date.getDate();
      var month = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
      var year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    // download() {
    //   return this.download;
    // },
  },
  watch: {
    ins: {
      handler: () => {
        this.$refs.addrss.innerHtml = this.formatAddress(this.ins.home_address);
        this.$refs.addrss1.innerHtml = this.formatAddress(
          this.ins.home_address
        );
      },
      deep: true,
    },
    download() {
      if (this.download === true) {
        // console.log("downloading");
        this.$refs.html2Pdf.generatePdf();
        // setTimeout(() => {
        //   // this.$refs.comm.generatePdf();
        //   // this.$refs.cons.generatePdf();
        //   // html2pdf(this.$refs.cons, {
        //   //   margin: 2,
        //   //   filename:
        //   //     "Statement of Consent to collect use and disclose credit report.pdf",
        //   // });
        //   // html2pdf(this.$refs.comm, {
        //   //   margin: 2,
        //   //   filename:
        //   //     "Statement of commitment to repay the principal and the accrued interest with the tenor.pdf",
        //   // });
        //   // this.$toast.info(
        //   //   "Document Downloads",
        //   //   "Please allow multiple downloads",
        //   //   this.$toastPosition
        //   // );
        // }, 800);
        // console.log("done");
      }
    },
  },
  mounted() {
    // console.log("mount");
    // this.$refs.addrss.innerHTML = this.formatAddress(this.ins.home_address);
    this.$refs.addrss1.innerHTML = this.formatAddress(this.ins.home_address);
  },
};
</script>

<style scoped lang='scss'>
.pdf {
  width: 595px;
  max-width: 595px;
  .ech {
    height: 842px;
    background-color: #fff;
    padding: 16px;
    color: #000;
    margin-top: 32px;
    margin-top: 32px;
    border: 1px solid #255e13;
    position: relative;
    .cadr {
      p {
        margin: 0;
      }
      .dt {
        margin-top: 12px;
      }
      margin-bottom: 32px;
    }
    /* .badr {
    }
    .bod {
    } */
    .bod {
      .tl {
        width: 100%;
        text-align: center;
        text-decoration: underline;
        font-weight: 700;
      }
    }
  }
  img.sign {
    width: 120px;
    height: 90px;
  }
  .bd {
    font-weight: 700;
  }
}
</style>